
import React from 'react'
import Carousel  from 'react-bootstrap/Carousel'

import gall1 from '../assets/gall1.png'
import gall2 from '../assets/gall2.png'
import gall3 from '../assets/gall3.png'
import gall6 from '../assets/gall6.png'
import gall7 from '../assets/gall7.png'
import gall8 from '../assets/gall8.png'
import bann from '../assets/bannernew.png'
import bann1 from '../assets/bannernew1.png'
import bann2 from '../assets/bannernew5.png'


function Gallery() {
  return (
    <>
    <div className='container-fluid py-3'>
      <h5 className='titlesec'>Image Gallery</h5>
      <div className='row'>
    <Carousel>
       
    <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann2}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann1}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={bann}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall1}
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall2}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall3}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall6}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall7}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={gall8}
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  
      </div>
    </div>
    </>
  )
}

export default Gallery