import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import vbanner from '../assets/vbanner.png'
import vbanner1 from '../assets/vbanner1.png'
import vbanner2 from '../assets/vbanner2.png'
import vbanner3 from '../assets/vbanner3.png'

function Home() {
  return (
    <>
    <Container fluid className='px-0'>
      <Row className='mx-0'>
        <Col sm={12} className='w-100 px-0'>
          <div className='tickerwrap d-flex'>
            <div className='tickername bghead pt-3 mb-0' style={{height:50,width:150}}>
              <p className='text-white fw-bold'>Upcoming</p>
            </div>
            <div className='tickercontent bggrey1 w-100'>
            <marquee direction="left" scrolldelay="50" scrollamount="5" truespeed="" onmouseover="this.stop()" onmouseout="this.start()" behavior="scroll" height="25px">
              <ul className='tickernewsbar text-center'>
                <li className='mx-4 pt-3'><b>August 24th - Punayavachanam + Special Alankaram + Laksharchana</b></li>
                <li className='mx-4 pt-3'><b>August 25th - Sri Maha Sudarshana Homam + Sri Vishnu Sahasranama Parayanam</b></li>
                <li className='mx-4 pt-3'><b>August 26th - Janmashtami Special Alankaram + Sarvadarshanam + Oonjala Seva</b></li>
                <li className='mx-4 pt-3'><b>August 27th - Varshika Snapanostavam + Pancharathra Aradhanam</b></li>
                <li className='mx-4 pt-3'><b>Note : SRIMAD VALMIKI RAMAYANAM LECTURES (Every Ekadasi 8.30 pm to 9.30 pm )</b></li>
                
              </ul>
              </marquee>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

    <Container fluid className='px-0'>
    <Carousel>
      <Carousel.Item interval={1000}>
      <img
          className="d-block  w-100"
          src={vbanner}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={500}>
      <img
          className="d-block  w-100"
          src={vbanner1}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block  w-100"
          src={vbanner2}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block  w-100"
          src={vbanner3}
          alt="fourth slide"
        />
      </Carousel.Item>
    </Carousel>
    </Container>
    <Container>
      <Row className='py-4'>
        <Col sm={12}>
          <h5 className='titlesec'>About</h5>
          <p className='abt'>Sri Venugopala Swamy temple is around 120 years old and is probably the oldest temple in Bolaram. The presiding deity here is Sri Rukmini Satyabhama Godhadevi sametha Sri Venugopala Swamy. Also, the presiding deity is Chaturbhuja Venugopala Swamy (Lord Krishna with 4 hands, flute and a cow) which is very unique and exclusive to the Sri Vaishnava Pancharatra tradition. The daily, fortnightly, monthly and annual rituals are being performed in maximum accordance to the Shastras and with the active participation of devotees for several decades now.
          <br/>
          <br/>
          Sri Venugopala Swamy Temple, Bolarum was built around 1902 by C.R. Subba Rao Mudaliar. Taking the legacy forward, his son C.S. Krishna Swamy Mudaliar established temple organization and strengthened the social and cultural ties of the community. The third generation kept the Temple flag flying high with visionary C.K. Kannabhiram Mudaliar and later passing on the mantle to the fourth generation.
          <br/>
          <br/>
          Currently, it is managed by CK Arjun Rao, the 4th generation trustee of the temple.
          </p>
        </Col>
      </Row>
    </Container>
    <section className='bggrey1 py-4'>
    <div className='container'>
      <div className='row pb-3'>
        <h5 className='titlesec pb-3'>Sri Venugopala Swamy Temple Trust</h5>
        <div>
        <div class="card-group">
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">President</h5>
      <p class="card-text">C.K.Arjun Rao</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Vice President</h5>
      <p class="card-text">S.Mohan</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">General Secretary</h5>
      <p class="card-text">V.S Varmesh</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Treasurer</h5>
      <p class="card-text">A.D Sridhar</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Joint Secretary</h5>
      <p class="card-text">J.B Jagadeep</p>
      <p class="card-text">V.K. Raghunath</p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Members</h5>
      <p class="card-text">V.K Mohan Rajan</p>
      <p class="card-text">C.R.Sanjay Srivatsan</p>
      <p class="card-text">S.V. Vidya Sagar</p>
    </div>
  </div>
</div>
        </div>
      </div>
      </div>
      </section>
      <Container>
        <Row>
          <Col sm={12}>
            <h5 className='titlesec py-2'>Temple Overview</h5>
          <div className="ratio ratio-16x9">
  <iframe src="https://www.youtube.com/embed/rg6aAOU52Cw?si=NVMe_1sUlmqzagxF" title="YouTube video" allowFullScreen></iframe>
          </div>

          </Col>
        </Row>
      </Container>




<section className='bggrey1'>
      <Container className='py-3'>
        <Row>
          <Col sm={12}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='border-bottom pb-2 titlesec'>Upcoming Events(Janmashtami Celebrations)</h5>
                <table class="table">
  <tbody>
    <tr>
      <th scope="row" >24th August, 2024
      <br/>
      (Saturday)
      </th>
      <td colspan="3">
      06:00 am <strong>PUNAYAVACHANAM</strong> 
      <br/>
     <strong>SPECIAL ALANKARAM</strong>  
      <br/>
      <strong>LAKSHARCHANA</strong> From 10:00 am
      </td>
    </tr>
    <tr>
      <th scope="row">25th August, 2024
      <br/>
      (Sunday)
      </th>
      <td colspan="3">
      Morning <strong>"Sri Maha Sudarshana Homam"</strong> From 10:00 am
      <br/>
      Evening <strong>Sri Vishnu Sahasranama Parayanam</strong>
      </td>
    </tr>
    <tr>
      <th scope="row">26th August, 2024
      <br/>
      (Monday)
      </th>
      <td colspan="3">
    06:00 am <strong>"JANMASHTAMI SPECIAL ALANKARAM"</strong> 
    <br/>
    Followed by <strong>Sarvadarshanam</strong>
    <br/>
    Evening <strong>Oonjala Seva (Cradle Kainkaryam)</strong>
      </td>
    </tr>
    <tr>
      <th scope="row">27th August, 2024
      <br/>
      (Tuesday)
      </th>
      <td colspan="3">
       <strong>"Varshika Snapanostavam"</strong> From 10:00 am 
       <br/>
       07:00 pm <strong>Pancharathra Aradhanam</strong>
       <br/>
       (For All 4days Pooja Rs.501 /- Only)

      </td>
    </tr>
  
    
  </tbody>
</table>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
</section>
    </>
  )
}

export default Home