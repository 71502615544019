import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import twitter1 from '../assets/twitter1.png'
import facebook1 from '../assets/facebook1.png'
import youtube1 from '../assets/youtube1.png'
import thiruman_final from '../assets/thiruman_final.png'
import instagram from '../assets/instagram.png'
import venut from '../assets/venut.png'

function Header() {
  return (
    <div>
        <Container fluid>
          <Row className='bgtop py-2'>
            <Col sm={2}>Contact: 9849051627</Col>
            <Col sm={6}>Temple Timings: (Morning) 6:30 AM - 10:30 AM /(Evening) 6:30 PM - 8:30 PM</Col>
           <Col sm={4}>
            <a href='https://www.facebook.com/profile.php?id=100084688486447&mibextid=ZbWKwL' target='_blank'><img src={facebook1} className='px-2' /></a>
           <a><img src={twitter1} className='px-2' /></a> 
            <a href='https://www.youtube.com/@srivenugopalaswamytemple-b3648' target='_blank'><img src={youtube1} className='px-2' /></a>
           <a><img src={instagram} className='px-2' /></a> 
           </Col>
          </Row>
            <Row className='bghead align-items-center'>
              <Col sm={4}>
              <img src={venut} className='pt-1' />
              </Col>
                  <Col sm={4}>
                  <img 
                  src={thiruman_final}
                  style={{height:113,width:265}}
                  />
                  </Col>
                  
                  <Col sm={4}>
                    <a href='https://rzp.io/l/e879bBWn' className='btn btn-warning fw-bold'>Donate Now</a>
                  </Col>
                  

            </Row>
        </Container>
    </div>
  )
}

export default Header