import React from 'react'

import sanjay from '../assets/sanjay.jpeg'
import ckarjun from '../assets/ckarjun.jpeg'

import varmesh3 from '../assets/varmesh3.png'


function Contact() {
  return (
    <>

<section className='bggrey1'>
<div className='container'>
  <div className='row py-4'>
    <div className='col-sm-4'>
    <div className='card justify-content-center align-items-center'>
        <img className='avatar py-2' src={ckarjun}  />
        <div className='card-body'>
          <h5>C.K.Arjun Rao</h5>
          <h6>+919849051627</h6>
          </div>
      </div>
      
</div>
    <div className='col-sm-4'>
    <div className='card justify-content-center align-items-center'>
        <img className='avatar py-2' src={varmesh3}  />
        <div className='card-body'>
          <h5>V.S Varmesh</h5>
          <h6>+919441051658</h6>
            </div>
      </div>
    </div>
    <div className='col-sm-4'>
    <div className='card justify-content-center align-items-center'>
        <img className='avatar py-2' src={sanjay}  />
        <div className='card-body'>
          <h5>C.R.Sanjay Srivatsan</h5>
          <h6>+919703765756</h6>
        </div>
      </div>
    </div>
  </div>
  <div className='row'>
    <div className='col-sm-12'>
    <h5 className='titlesec py-2'>Temple Address:</h5>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d336.3090748822498!2d78.51824196089217!3d17.512150942602656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9ad79aa47c07%3A0x547abe240e43227d!2sSri%20Venugopala%20Swamy%20Temple!5e0!3m2!1sen!2sin!4v1693842830989!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</div>


</section>
    </>
  )
}

export default Contact