import React from "react";
import thanks from '../assets/thanks.png';

function Thankyou () {
    return(
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-6 py-3">
                        <h5>Thank you for donating to Sri Venugopala Swamy Temple.</h5>
                        <p className="fw-bold">Jaya Jaya Sri Sudarsana!</p>
                        <img className="" style={{height:300}} src={thanks} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Thankyou